var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _c("gov-back-link", { attrs: { to: { name: "services-index" } } }, [
        _vm._v("Back to services")
      ]),
      _c(
        "gov-main-wrapper",
        [
          _vm.loading
            ? _c("ck-loader")
            : _c(
                "gov-grid-row",
                [
                  _c("vue-headful", {
                    attrs: {
                      title: _vm.appName + " - Service: " + _vm.service.name
                    }
                  }),
                  _c(
                    "gov-grid-column",
                    { attrs: { width: "full" } },
                    [
                      _c(
                        "gov-grid-row",
                        [
                          _c(
                            "gov-grid-column",
                            { attrs: { width: "two-thirds" } },
                            [
                              _c(
                                "gov-heading",
                                { attrs: { size: "m" } },
                                [
                                  _c("gov-caption", { attrs: { size: "m" } }, [
                                    _vm._v(_vm._s(_vm.service.name))
                                  ]),
                                  _vm._v(
                                    "\n              View " +
                                      _vm._s(_vm.service.type) +
                                      "\n            "
                                  )
                                ],
                                1
                              ),
                              _vm.updated
                                ? _c("gov-inset-text", [
                                    _vm._v(
                                      _vm._s(_vm.service.type) +
                                        " " +
                                        _vm._s(_vm.service.name) +
                                        " has been\n              updated"
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.auth.canEdit("service", _vm.service.id)
                            ? _c(
                                "gov-grid-column",
                                {
                                  staticClass: "text-right",
                                  attrs: { width: "one-third" }
                                },
                                [
                                  _c(
                                    "gov-button",
                                    {
                                      attrs: {
                                        to: {
                                          name: "services-edit",
                                          params: { service: _vm.service.id }
                                        }
                                      }
                                    },
                                    [_vm._v("Edit " + _vm._s(_vm.service.type))]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "gov-tabs",
                        { attrs: { tabs: _vm.tabs } },
                        [
                          _c("router-view", { attrs: { service: _vm.service } })
                        ],
                        1
                      ),
                      _vm.canDelete
                        ? [
                            _c("gov-body", [
                              _vm._v(
                                "Please be certain of the action before deleting a\n            " +
                                  _vm._s(_vm.service.type)
                              )
                            ]),
                            _c("gov-section-break", { attrs: { size: "l" } }),
                            _c("ck-delete-button", {
                              attrs: {
                                resource: _vm.service.type,
                                endpoint: "/services/" + this.service.id
                              },
                              on: { deleted: _vm.onDelete }
                            })
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }